
import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import { useStore } from 'vuex';

import PrimeVueMessage from 'primevue/message';

import type { Project } from '@/types';

import {
  THEME_LIGHT,
  THEME_DARK,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import TopBar from '@/components/TopBar.vue';

export default defineComponent({
  components: {
    PrimeVueMessage,
    TopBar,
  },
  setup() {
    const store = useStore();

    const { activeUser: user } = useUser();

    const layoutMobileActive = ref<boolean>(false);

    const layoutWrapperClass = computed(() => ({
      'layout-mobile-active': layoutMobileActive.value,
      'layout-topbar-light': store.getters.themeIsLight,
      'layout-menu-light': store.getters.themeIsLight,
      'layout-topbar-dark': store.getters.themeIsDark,
      'layout-menu-dark': store.getters.themeIsDark,
    }));

    const toggleTheme = (): void => {
      const theme = store.getters.themeIsLight ? THEME_DARK : THEME_LIGHT;

      store.commit('SET_THEME', theme);
    };

    const toggleMenu = (): void => {
      layoutMobileActive.value = !layoutMobileActive.value;
    };

    const onLayoutMaskClick = (): void => {
      toggleMenu();
    };

    return {
      THEME_LIGHT,
      THEME_DARK,
      user,
      project: computed((): Project => store.getters.project),
      themeIsLight: computed((): boolean => store.getters.themeIsLight),
      layoutWrapperClass,
      toggleTheme,
      toggleMenu,
      onLayoutMaskClick,
    };
  },
});
